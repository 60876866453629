import ls from 'local-storage';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { FEATURESFLAG } from 'gatsby-env-variables';
import root from 'window-or-global';
import 'typeface-roboto';

const { hackMode = false, darkMode = false } = ls.get(FEATURESFLAG) || {};

export const userPrefersDark = () => {
  const { matchMedia = () => ({ matches: false }) } = root || {};
  const preferDark = matchMedia('(prefers-color-scheme: dark)').matches;
  return preferDark;
};

export const isDark = () => {
  if (hackMode) {
    return darkMode;
  }

  return true; // userPrefersDark();
};

const theme = createTheme({
  palette: {
    type: isDark() ? 'dark' : 'light',
    primary: {
      light: '#E3F2FD',
      main: '#2196F3',
      dark: '#1565C0',
      contrastText: '#fff',
      bright: '#5CBFFD',
    },
    secondary: {
      light: '#00BFA5',
      main: '#00BFA5',
      dark: '#00796B',
      contrastText: '#fff',
      bright: '#7FDED1',
    },
    background: {
      default: isDark() ? '#212121' : '#F9F9F9',
      paper: isDark() ? '#2B2B2B' : '#fff',
    },
    text: isDark() ? {
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
      primary: '#fff',

      secondary: 'rgba(255, 255, 255, 0.7)',
    } : {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    logo: {
      green: '#50b548',
      red: '#ef5645',
      blue: '#419dcb',
      orange: '#f79e2d',
      pink: '#f76e62',
    },
  },
  invertedBackgroundColor: isDark() ? '#FFF' : '#2B2B2B',
  invertedColor: isDark() ? '#000' : '#fff',
  borderColor: isDark() ? '#F9F9F9' : '#212121',
  buttonBackgroundColor: '#2289BB',
  buttonBackgroundColorHover: '#0774A9',
  secondButtonBackgroundColor: '#359E2A',
  secondBackgroundColorHover: '#1E8014',
  appBarBackgroundColor: '#1565C0',
  typography: {
    useNextVariants: true,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    htmlFontSize: 16,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  breakpoints: {
    values: {
      xs: 0,
      xsm: 400,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
      iphoneFive: 350,
    },
  },
});

const footer = createTheme({
  palette: {
    type: isDark() ? 'dark' : 'light',
    primary: {
      light: '#00BFA5',
      main: '#00BFA5',
      dark: '#00796B',
      contrastText: '#fff',
    },
    background: {
      default: isDark() ? '#212121' : '#F9F9F9',
      paper: isDark() ? '#2B2B2B' : '#fff',
    },
    secondary: {
      light: '#E3F2FD',
      main: '#2196F3',
      dark: '#1565C0',
      contrastText: '#fff',
    },
    text: {
      default: isDark() ? '#2B2B2B' : '#F9F9F9',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
  },
});

export const footerTheme = responsiveFontSizes(footer);

export default responsiveFontSizes(theme);
